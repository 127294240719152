import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import queryString from "query-string";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";

import {
  checkPro,
  isLoggedIn,
  removePro,
  setPro,
  setToken,
} from "../lib/user.auth";
import { navigate, graphql } from "gatsby";
import { postToApi } from "../lib/api";
import { isBrowser } from "../lib/config";
import creditcard from "../images/credit-card2.jpg";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.NODE_ENV === "development"
        ? "pk_test_51K160kCBDHuiV2AMymoYxFYD6in0Ab5HZAlxZ7GY8qsOKqzo0i5QwRS18o1UFOzKOy4jRdnr56Qoou5vL2YCnh7K00vwZgz5GX"
        : "pk_live_51K160kCBDHuiV2AMto6P4FLXbYt0Uy7UeW5cWLtx33rXlfB0EKm2ZfM4XOPkCLHVngaL8hOW5k3fSlRPh7BBvoi400HCw9QoY1"
    );
  }
  return stripePromise;
};

const CyberWeek = () => {
  const { t } = useTranslation();
  const p_id =
    process.env.NODE_ENV === "development"
      ? "price_1K17ARCBDHuiV2AMFKaLSeBm"
      : "price_1K17AiCBDHuiV2AMyrHsMKDd";

  const runStripe = async () => {
    //console.log("Run the payment ...");
    const url = window.location.origin + window.location.pathname;
    try {
      const stripe = await getStripe();
      const d = new Date();
      let time = d.getTime();
      Cookies.set("checkuser", time);
      const session = await stripe.redirectToCheckout({
        mode: "payment",
        locale: "en",
        lineItems: [
          {
            price: p_id,
            quantity: 1,
          },
        ],
        successUrl: `${url}?dKpqoamoidquwiFsadHJwpdasunzduaifitrewGGfsSazsdfzdb=${time}&success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${url}?canceled=true`,
      });
      navigate(session.url);
    } catch (err) {
      console.log(err);
    }
  };

  const [success, setSuccess] = React.useState(false);

  const showPrice = "0,99";
  const showPriceBottom = (
    <>
      Pay <s>39,96€/yearly</s> 0,99€/once
    </>
  );

  const paypalOnError = (err) => {
    removePro();
    alert("There was an unexpected error. Please try again later.");
    console.log("Error");
  };

  const paypalOnApprove = async (data, detail) => {
    // call the backend api to store transaction details
    //console.log("Payapl approved");
    const result = await postToApi("/1.0/add-plan", {
      subscriptionID: data.subscriptionID,
    });
    if (result.code === 200) {
      setPro();
      navigate("/success");
    } else {
      alert("There was a problem. Please contact our support.");
    }
    //console.log(data.subscriptionID);
  };

  useEffect(() => {
    if (isBrowser()) {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
      setSuccess(false);
      if (query.get("success") && query.get("success")) {
        if (
          query.get("dKpqoamoidquwiFsadHJwpdasunzduaifitrewGGfsSazsdfzdb") ===
            Cookies.get("checkuser") &&
          Cookies.get("checkuser") != null
        ) {
          setSuccess(true);
          paypalOnApprove({ subscriptionID: "stripe" });
        } else {
          Cookies.remove("checkuser");
          paypalOnError();
        }
        return;
      }
      if (query.get("canceled")) {
        Cookies.remove("checkuser");
        paypalOnError();
        return;
      }
      let url = window.location.search;
      let params = queryString.parse(url, { decode: false });
      if (params.__) {
        setToken(params.__);
      }
    }
    if (checkPro()) navigate("/success");
    //console.log(process.env.NODE_ENV);
  }, [success]);

  return (
    <Layout>
      <Seo title={t("Cyber Week Offer")} />
      <h1>{t("Cyber Week Offer")}</h1>
      <h2>
        Not just <b>Black Friday</b> - no, we're making it longer !!
      </h2>
      <h3>Get it now LIFE LONG for just ONCE 0,99 € !!</h3>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="flex-end"
        style={{ marginTop: "40px" }}
      >
        <Grid item key="pro_y" xs={12} md={5}>
          <Card>
            <CardHeader
              title="Pro"
              subheader="Unlock all features of my-follower.com"
              titleTypographyProps={{ align: "left" }}
              subheaderTypographyProps={{ align: "left" }}
              action={<StarIcon />}
              style={{ background: "#e9e9e9" }}
            />
            <CardContent>
              <ul>
                <li>
                  <span>✓</span> {t("No ads")}
                </li>
                <li>
                  <span>✓</span> {t("All social media platforms")}
                </li>
                <li>
                  <span>✓</span> {t("Multiple social media accounts")}
                </li>
                <li>
                  <span>✓</span> {t("Individual times for the e-mail")}
                </li>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
              >
                <Typography component="h2" variant="h3" color="textPrimary">
                  {showPrice}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  /once
                </Typography>
              </div>
              <ul>
                <Typography align="center" key="free_line">
                  <small>{showPriceBottom}</small>
                </Typography>
              </ul>
            </CardContent>
            <Grid style={{ margin: 20 }}>
              {!isLoggedIn() ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/accounts");
                  }}
                >
                  Go pro!
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: "#f2f2f2" }}
                    onClick={() => runStripe()}
                  >
                    <img
                      src={creditcard}
                      alt="Credit Card"
                      title="Credit or Debit Card via Stripe"
                      border="0"
                      style={{ width: "100%", margin: 0 }}
                    />
                  </Button>
                  <br />
                  <center>
                    <small>via Stripe</small>
                  </center>
                </>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CyberWeek;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
